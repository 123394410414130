import React from "react"
import HeadingM from "../shared/HeadingM"
import ContactOtherBase from "../shared/contact/ContactOther"
import { Target } from "../../utils"

const ContactOther = () => {
  const data = {
    target: "do" as Target,
    tel: "0278-25-4772",
    openingHour: "9:00〜18:00",
    lineId: "027fmlqg@",
    addFriendLink: "nZmhfwi",
    fax: "0278-25-4773",
    email: "do@noone.jp",
    instagram: "do20220401",
  }
  return (
    <div className="mt-12 md:mt-16">
      <HeadingM title="その他" target="do" />
      <ContactOtherBase {...data} />
    </div>
  )
}

export default ContactOther
